import { withStory, WithStoryProps } from "@storyofams/storyblok-toolkit";

import { GetStaticProps, NextPage } from "next";

import Head from "next/head";
import Script from "next/script";
import { DynamicSections, Seo } from "../components";
import {
  enhancedStaticProps,
  loadCollectionSections,
  loadPopularProducts,
  withLayout,
  loadProducts,
} from "../lib";

interface HomePageProps extends WithStoryProps {}

const Home: NextPage<HomePageProps> = ({ story }) => {
  return (
    <>
      <Seo story={story} />
      <Script
        dangerouslySetInnerHTML={{
          __html: `var SKIP_CART=true;
        var FORCE_DOMAIN = true;
        var VIDEOWISE_FAST_INLINE_VIDEO_PLAYER = true;
        var Shopify = { 
          shop: 'natural-heroes-nl.myshopify.com', currency:{active:'EUR', rate:'1.0'}
        };

        var __st = {
          rid: null,
          p: 'home'
        };`,
        }}
      />
      <Script
        defer
        src="https://assets.videowise.com/vendors.js.gz"
        id="videowise-vendors-js"
      />
      <Script
        defer
        src="https://assets.videowise.com/client.js.gz"
        id="videowise-client-js"
      />
      <DynamicSections sections={story?.content?.body} />
    </>
  );
};

export default withLayout(withStory(Home));

export const getStaticProps: GetStaticProps<HomePageProps> =
  enhancedStaticProps(async ({ sdk, locale, defaultLocale }) => {
    let story = null;
    let notFound = false;
    try {
      story = (
        await sdk.home({
          slug: defaultLocale !== locale ? `${locale}/home` : "home",
        })
      ).HomeItem;
    } catch (err) {
      notFound = true;
    }
    await loadCollectionSections(story, locale);

    await loadPopularProducts(story, locale);

    await loadProducts(story, locale);

    return {
      props: {
        story,
      },
      notFound: notFound || !story,
      revalidate: 600,
    };
  });
